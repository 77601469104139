import * as React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  SortDescriptor,
  Selection,
  Chip,
} from "@nextui-org/react";
import { OrderEppList, VendorType } from "@/src/types";
import {
  GetEppCartApi,
  GetVendorList,
  PostPlaceOrderApi,
} from "../../../../apis.ts";
import useApi from "../../../../hooks/useApi.tsx";
import { formatDateIntl } from "../../../../utils/utils.ts";
import Row from "../../../../components/ui/Row.tsx";
import Label from "../../../../components/ui/Label.tsx";
import Spacer from "../../../../components/ui/Spacer.tsx";
import Button from "../../../../components/ui/Button.tsx";
import useToast from "../../../../hooks/useToast.tsx";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "../../../../components/common/Select.tsx";

const COLUMNS = [
  {
    name: "Sr No.",
    key: "sr_no",
  },
  {
    name: "Employee Name",
    key: "employee_name",
    sortable: true,
  },
  {
    name: "Device Configuration",
    key: "device_configuration",
  },

  {
    name: "Request Date",
    key: "request_date",
  },
  {
    name: "Rental Amount",
    key: "rental_amount",
    sortable: true,
  },

  {
    name: "Quantity",
    key: "quantity",
  },
  {
    name: "Status",
    key: "status",
  },
];

export default function MyCart() {
  const [cart, setCart] = React.useState<OrderEppList[]>([]);
  const [vendors, setVendors] = React.useState<VendorType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [filterValue, setFilterValue] = React.useState("");
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>({
    column: "employee_name",
    direction: "ascending",
  });

  const [selectedKeys, setSelectedKeys] = React.useState<Selection>(
    (cart?.length ?? 0) === 0 ? new Set([]) : "all",
  );

  const { makeApiCall } = useApi();

  const { showToast } = useToast();

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetEppCartApi())
      .then((response) => {
        if (response !== undefined) {
          setCart(response?.orders);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const placeOrder = React.useCallback(
    ({ vendor }: { vendor: string }) => {
      let output: string[] = []; // Array to store the output
      if (selectedKeys === "all") {
        output = cart.map((item) => `${item.ID}`); // Collect all item IDs in the cart
      } else {
        selectedKeys.forEach((item) => {
          output.push(item as string); // Collect all selected keys
        });
      }
      return makeApiCall(PostPlaceOrderApi(vendor, output))
        .then(() =>
          showToast("Status updated successfully", { type: "success" }),
        )
        .catch(() => showToast("Status updation failed", { type: "error" }));
    },
    [cart, makeApiCall, selectedKeys, showToast],
  );

  React.useEffect(() => {
    setLoading(true);
    makeApiCall(GetVendorList())
      .then((response) => {
        if (response !== undefined) {
          setVendors(response?.vendors);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [makeApiCall]);

  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...cart];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((cart) =>
        cart.EmployeeName.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filteredUsers;
  }, [cart, filterValue, hasSearchFilter]);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a: OrderEppList, b: OrderEppList) => {
      const first = a[sortDescriptor.column as keyof OrderEppList] as number;
      const second = b[sortDescriptor.column as keyof OrderEppList] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const onRowsPerPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRowsPerPage(Number(e.target.value));
      setPage(1);
    },
    [],
  );

  const pages = React.useMemo(() => {
    if (cart?.length === 0) {
      return 1;
    }
    return Math.ceil((cart?.length ?? 1) / rowsPerPage);
  }, [cart?.length, rowsPerPage]);

  const { totalQuantity, totalRentalAmount } = React.useMemo(() => {
    const totalQuantity = cart?.reduce((sum, order) => sum + order.Quantity, 0);

    const totalRentalAmount = cart?.reduce((sum, order) => {
      const amount = parseFloat(order.RentalAmount.replace(/[^0-9.-]+/g, ""));
      return sum + (isNaN(amount) ? 0 : amount);
    }, 0);

    return { totalQuantity, totalRentalAmount };
  }, [cart]);

  const validationSchema = Yup.object().shape({
    vendor: Yup.string().required("Vendor is required"),
  });

  const vendorsToSelectTypes = React.useMemo(() => {
    return vendors.map((vendor) => ({
      label: vendor.vendor_name,
      value: vendor.vendor_id,
    }));
  }, [vendors]);

  const bottomContent = React.useMemo(() => {
    return (
      <>
        {cart?.length > 0 && (
          <Row justifyContent="flex-end" alignItems="center">
            <Label>Total</Label>
            <Spacer size="lg" orientation="horizontal" />
            <div>{totalRentalAmount}</div>
            <Spacer size="lg" orientation="horizontal" />
            <div>{totalQuantity}</div>
            <Spacer size="md" orientation="horizontal" />
            <div className="w-40">
              <Formik
                initialValues={{ vendor: "" }}
                onSubmit={placeOrder}
                validateOnBlur
                validateOnChange
                validationSchema={validationSchema}
                enableReinitialize
              >
                <Form>
                  <Select
                    name="vendor"
                    label="Select vendor"
                    item={vendorsToSelectTypes}
                    size="sm"
                  />
                  <Spacer size="xs" />
                  <Button
                    size="sm"
                    disabled={
                      selectedKeys !== "all" && selectedKeys?.size === 0
                    }
                  >
                    Place Order
                  </Button>
                </Form>
              </Formik>
            </div>
          </Row>
        )}
        <div className="py-2 px-2 flex justify-between items-center">
          <Pagination
            showControls
            classNames={{
              item: "bg-pageBackground w-6 h-6 min-w-4 font-roboto",
              cursor: "w-6 h-6 min-w-4 font-roboto",
            }}
            color="default"
            isDisabled={hasSearchFilter}
            page={page}
            total={pages}
            variant="light"
            onChange={setPage}
          />
          <div className="flex justify-between items-center">
            <label className="flex items-center text-small font-roboto text-black font-light ">
              Items per page :&nbsp;
              <select
                className="border-none shadow-sm outline-none text-default-400 text-small font-roboto font-light px-1 py-0 rounded-md"
                onChange={onRowsPerPageChange}
                defaultValue={"20"}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </label>
          </div>
        </div>
      </>
    );
  }, [
    cart?.length,
    totalRentalAmount,
    totalQuantity,
    placeOrder,
    validationSchema,
    vendorsToSelectTypes,
    selectedKeys,
    hasSearchFilter,
    page,
    pages,
    onRowsPerPageChange,
  ]);

  const renderStatus = React.useCallback((item: OrderEppList) => {
    switch (item?.Status) {
      case "approved":
        return (
          <Chip variant="flat" color="success" size="sm">
            <span className="font-extrabold">&#8226;</span> Approved
          </Chip>
        );
      default:
        return (
          <Chip variant="flat" color="success" size="sm">
            <span className="font-extrabold">&#8226;</span> Active
          </Chip>
        );
    }
  }, []);

  const renderCell = React.useCallback(
    (item: OrderEppList, columnKey: React.Key) => {
      const index = cart.map((object) => object.ID).indexOf(item.ID);
      switch (columnKey) {
        case "sr_no":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{index + 1}</p>
            </div>
          );
        case "employee_name":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item.EmployeeName}
              </p>
            </div>
          );
        case "device_configuration":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item.ProductSpecification}
              </p>
            </div>
          );
        case "request_date":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {formatDateIntl(item.OrderDate)}
              </p>
            </div>
          );
        case "rental_amount":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {item.RentalAmount}
              </p>
            </div>
          );
        case "quantity":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">{item.Quantity}</p>
            </div>
          );
        case "status":
          return (
            <div className="flex flex-col">
              <p className="text-bold text-sm capitalize">
                {renderStatus(item)}
              </p>
            </div>
          );
      }
    },
    [cart, renderStatus],
  );

  const classNames = React.useMemo(
    () => ({
      th: [
        "bg-transparent",
        "text-tableHeaderColor",
        "border-b",
        "border-divider",
        "font-roboto",
        "font-regular",
        "text-sm",
      ],
      td: [
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        "group-data-[middle=true]:before:rounded-none",
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
        "font-roboto",
        "font-normal",
        "text-textColorGrey",
      ],
      table: "min-h-[350px]",
      wrapper: "table-wrapper",
    }),
    [],
  );

  return (
    <Table
      selectionMode="multiple"
      classNames={classNames}
      bottomContent={bottomContent}
      bottomContentPlacement="inside"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}
    >
      <TableHeader columns={COLUMNS}>
        {(column) => (
          <TableColumn
            allowsSorting={column.sortable}
            key={column.key}
            align={column.key === "action" ? "end" : "start"}
            width={column.key === "action" ? 100 : undefined}
            className="text-center"
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={!loading && "No rows to display."}
        items={sortedItems}
        isLoading={loading}
        loadingContent={<Spinner label="Loading..." />}
      >
        {(item) => (
          <TableRow key={`${item.ID}`} className="cursor-pointer h-12 ">
            {(columnKey) => (
              <TableCell className="text-center">
                {renderCell(item, columnKey)}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
